import { type AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser';
import { AuthConfiguration } from './authConfig';

export const MsalInstance = new PublicClientApplication(AuthConfiguration);

// Account selection logic is app dependent. Adjust as needed for different use cases.
if (!MsalInstance.getActiveAccount() && MsalInstance.getAllAccounts().length > 0) {
    MsalInstance.setActiveAccount(MsalInstance.getAllAccounts()[0]);
}

/**
 * To set an active account after the user signs in, 
 * register an event and listen to LOGIN_SUCCESS & LOGOUT_SUCCES. 
 * For more, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 */
MsalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        MsalInstance.setActiveAccount(account as AccountInfo);
    }

    if (event.eventType === EventType.LOGOUT_SUCCESS) {
        if (MsalInstance.getAllAccounts().length > 0) {
            MsalInstance.setActiveAccount(MsalInstance.getAllAccounts()[0]);
        }
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log(JSON.stringify(event));
    }
});
