import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import client from './en/client.json';

export const resources = {
  en: {
    client,
  },
} as const;

void i18next.use(initReactI18next).init({
  lng: 'en',
  debug: true,
  resources,
  interpolation: {
    escapeValue: false,
  },
});
