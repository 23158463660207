import { Route, Routes } from 'react-router';
import { PageNotFound } from '../admin/pages/PageNotFound';
import { Suspense, lazy } from 'react';

const ClientRouteLazy = lazy(
  async () => await import('../clients/routes/ClientRoute'),
);
const AdminRouteLazy = lazy(
  async () => await import('../admin/routes/AdminRoute'),
);

export const AppRoute = (): JSX.Element => {
  return (
    <Suspense>
      <Routes>
        <Route path="fpp">
          <Route path="admin/*" element={<AdminRouteLazy />} />
          <Route path="*" element={<ClientRouteLazy />} />
          <Route path="" element={<PageNotFound />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};
