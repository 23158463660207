import ReactDOM from 'react-dom/client';
import 'jquery';
import 'popper.js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/index.css';
import { MsalInstance } from "./auth/initMsal";

const rootElement = document.getElementById('root');

if (rootElement === null) throw new Error("Can't find root element");

const root = ReactDOM.createRoot(rootElement);
root.render(<App msalInstance={MsalInstance}/>);

reportWebVitals();
