import { BrowserRouter } from 'react-router-dom';
import { AppRoute } from './routes/AppRoute';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MsalProvider } from "@azure/msal-react";
import { type PublicClientApplication } from "@azure/msal-browser";
import './texts/config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

interface AppProps {
  msalInstance: PublicClientApplication
}

const App = ({msalInstance}: AppProps): JSX.Element => {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
