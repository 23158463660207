export const AuthorityMetadata = `{
    "issuer": "${process.env.REACT_APP_AUTH_META_ISSUER}",
    "authorization_endpoint": "${process.env.REACT_APP_AUTH_META_AUTHORIZATION_ENDPOINT}",
    "token_endpoint": "${process.env.REACT_APP_AUTH_META_TOKEN_ENDPOINT}",
    "end_session_endpoint": "${process.env.REACT_APP_AUTH_META_END_SESSION_ENDPOINT}",
    "jwks_uri": "${process.env.REACT_APP_AUTH_META_JWKS_URI}",
    "response_modes_supported": [
        "query",
        "fragment",
        "form_post"
    ],
    "response_types_supported": [
        "code",
        "code id_token",
        "code token",
        "code id_token token",
        "id_token",
        "id_token token",
        "token",
        "token id_token"
    ],
    "scopes_supported": [
        "openid"
    ],
    "subject_types_supported": [
        "pairwise"
    ],
    "id_token_signing_alg_values_supported": [
        "RS256"
    ],
    "token_endpoint_auth_methods_supported": [
        "client_secret_post",
        "client_secret_basic"
    ],
    "claims_supported": [
        "dnvglAccountName",
        "myDnvglGuid",
        "userId",
        "oid",
        "name",
        "given_name",
        "family_name",
        "sub",
        "email",
        "upn",
        "mfaType",
        "mfa_required",
        "authenticatedBy",
        "iss",
        "iat",
        "exp",
        "aud",
        "acr",
        "nonce",
        "auth_time"
    ]
}`;
