import { type Configuration } from "@azure/msal-browser";
import { AuthorityMetadata } from "./authMetadata";

export const AuthConfiguration: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '', 
      authority: process.env.REACT_APP_AUTH_AUTHORITY ?? '',
      knownAuthorities: process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES
      ? process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES.split(',')
      : [],
      redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
      navigateToLoginRequestUrl: process.env.REACT_APP_AUTH_NAVIGATE_TO_LOGIN_REQUEST_URL
      ? process.env.REACT_APP_AUTH_NAVIGATE_TO_LOGIN_REQUEST_URL.toLowerCase() === 'true'
      : false,
      authorityMetadata: AuthorityMetadata
    }
};
